import {
  direction,
  size,
  Spacing,
  spacingType
} from "@/design/spacing/Spacing";

export const spaceableMixin = {
  data() {
    return {
      spacingDirection: direction,
      spacingSize: size,
      spacingType: spacingType
    };
  },
  methods: {
    spacingClass(spacingDirection = direction.right, spacingSize = size.size2) {
      return new Spacing(
        spacingType.margin,
        spacingDirection,
        spacingSize
      ).getClassSpacing();
    }
  }
};
