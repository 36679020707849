<template>
  <v-menu offset-y v-bind="$attrs" v-model="visibleMenu">
    <template v-slot:activator="{ on, attrs }">
      <!-- User Drop-Down Button -->
      <v-btn text v-bind="attrs" v-on="on">
        <v-badge
          :content="countMessages"
          :value="visibleBadge"
          :color="badgeColor"
          overlap
        >
          <v-icon :class="spacingClass(spacingDirection.right)">{{
            iconAccount
          }}</v-icon>
        </v-badge>

        <span v-show="visibleOnMdAndUp">
          {{ menuLabel }}
        </span>

        <v-icon :class="spacingClass(spacingDirection.left)">{{
          iconMenuDown
        }}</v-icon>
      </v-btn>
    </template>

    <!-- Display Drop-Down Button menu items when Drop-Down Button is clicked (activated) -->
    <base-nav-list>
      <!-- menu-item: User settings -->
      <popover-menu-user-settings
        @closed="onUserSettingsClosed"
      ></popover-menu-user-settings>

      <!-- menu-item: Change Repository -->
      <base-menu-item @click="onChangeRepository" v-show="!isPublicAccess">
        <template v-slot:iconName>
          {{ iconDatabase }}
        </template>
        <template v-slot:tooltipContent>
          <div>
            {{ labelChangeRepository }}
          </div>
        </template>
        {{ labelChangeRepository }}
      </base-menu-item>

      <v-divider v-show="!isPublicAccess"></v-divider>

      <!-- menu-item: Log Out -->
      <base-menu-item @click="onLogout" v-show="!isPublicAccess">
        <template v-slot:iconName>
          {{ iconLogOut }}
        </template>
        <template v-slot:tooltipContent>
          <div>
            {{ labelLogOut }}
          </div>
        </template>
        {{ labelLogOut }}
      </base-menu-item>
    </base-nav-list>
  </v-menu>
</template>

<script>
// model
import {
  commandNames,
  commandIcons
} from "@/model/common/commands/commandModel";

// Design
import {
  getSuccessColorClass,
  getErrorColorClass
} from "@/design/colors/Color";
import { iconMenuDown } from "@/design/icon/iconConst";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");

// Mixins
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { appModuleNavigatable } from "@/mixins/shared/navigatable/appModuleNavigatable";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { spaceableMixin } from "@/mixins/shared/space/spaceableMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

export default {
  name: "UserMenu",
  inheritAttrs: false,
  mixins: [
    reactiveMixin,
    appModuleNavigatable,
    menuItemSettingsMixin,
    spaceableMixin,
    userMixin,
    publicAccessMixin
  ],
  components: {
    PopoverMenuUserSettings: () =>
      import("@/components/shared/core/menu/popover/PopoverMenuUserSettings"),
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem")
  },
  data() {
    return {
      visibleMenu: false,
      iconLogOut: commandIcons.logOut,
      iconConfigure: commandIcons.configure,
      iconAccount: commandIcons.account,
      iconDatabase: commandIcons.iconDatabase,
      iconMenuDown: iconMenuDown
    };
  },
  computed: {
    countMessages() {
      return this.isUserLoggedIn ? 0 : 1;
    },
    visibleBadge() {
      return this.countMessages > 0;
    },
    badgeColor() {
      return this.isUserLoggedIn
        ? getSuccessColorClass()
        : getErrorColorClass();
    },
    labelChangeRepository() {
      return `Change Repository: ${this.repository}`;
    },
    menuLabel() {
      return `${this.repository}, ${this.actorName}`;
    },
    labelLogOut() {
      return `${commandNames.logOut} ${this.actorName}`;
    }
  },
  methods: {
    ...mapActions(["logout", "changeRepository"]),
    async onLogout() {
      try {
        await this.logout();
      } catch (e) {
        alert(e.toString());
      }
    },
    onChangeRepository() {
      try {
        this.changeRepository();
      } catch (e) {
        alert(e.toString());
      }
    },
    onUserSettingsClosed() {
      this.visibleMenu = false;
    },
    login() {
      try {
        const route = this.buildAppModuleRoute("Login");

        this.$router.push(route).catch(err => {
          console.error(err);
          alert(err?.toString() ?? "An error occurred");
          return Promise.reject(err);
        });
      } catch (e) {
        alert(e.toString());
      }
    }
  }
};
</script>
