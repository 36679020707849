// router
import { createAppModuleRoute } from "@/router/solution/solutionRoutes";

// model
import { moduleNames } from "@/model/solution/moduleModel";

// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { userSettingsMixin } from "@/mixins/shared/base/settings/userSettingsMixin";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";
import { principalMixin } from "@/mixins/shared/user/principalMixin";

/**
 * Application Module Navigatable mixin
 */
export const appModuleNavigatable = {
  mixins: [appMixin, userSettingsMixin, publicAccessMixin, principalMixin],
  data() {
    return {
      moduleNames: moduleNames
    };
  },
  computed: {
    /**
     * Get the list of Applications/Module-features available to current user
     * @returns {[{icon: string, name: string, title: string}]}
     */
    appModules() {
      return this.modules.filter(m => this.isAvailableModule(m.name));
    }
  },
  methods: {
    /**
     * Build Application Module Route
     * @param {String} moduleName
     * @returns {{name: String}}
     */
    buildAppModuleRoute: moduleName => createAppModuleRoute(moduleName),

    /**
     * is Available Application Module
     * @param {String|string} name Application Module
     * @return {Boolean|boolean} true if Application Module is Available
     */
    isAvailableModule(name) {
      return this.isAvailableApplicationModule(name);
    },

    /**
     * can Navigate To App Module
     * @param name App Module name
     * @return {boolean} true if can Navigate To App Module
     */
    canNavigateToAppModule(name) {
      return name !== this.appModuleName;
    },

    /**
     * navigate To App Module
     * @param {String|string} name App Module name
     * @return {Promise<void>}
     */
    async navigateToAppModule(name) {
      try {
        await this.$router.push(createAppModuleRoute(name));
      } catch (e) {
        console.error(e);
      }
    }
  }
};
